import React ,{useState,useEffect} from 'react';
import { Col, Container, Row, Card,CardBody } from 'reactstrap';
import { Button } from 'react-bootstrap';
import { useHistory,Link } from 'react-router-dom';
import {FaUserAlt,FaTable,FaListUl, FaEdit,FaTimes} from 'react-icons/fa'
import {HiPencilAlt, HiOutlineTrash} from 'react-icons/hi'
import Search from '../../../Helper/CustomSearch'
import axios,{post,get} from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import config from '../../../../config'
import empty from 'is-empty'
import Breadcrumbs from '../../../Helper/Breadcrumbs'
import Loader from '../../../Helper/Loader';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from "react-bootstrap-table2-paginator";
import {Pagination} from '@mui/material'
import {Dialog, DialogActions,DialogContent, DialogContentText,DialogTitle} from '@mui/material'

const icon = `${process.env.PUBLIC_URL}/img/images/order-images.jpg`;

const MandapList = (props) => {
  // console.log('props in MandapList',props)
  // console.log('props.history.location.state',props.history.location.state)
  let history = useHistory();
  let tokenKey = {headers:{Authorization:'Token '+sessionStorage.getItem('tokenKey')}};
  let propsState = props.history.location.state;
  let idFromRole = JSON.parse(sessionStorage.getItem('permission'))[0].vendor_ids[0] || null
  let vendorId = propsState && propsState.id ? propsState.id : idFromRole;
  let toastAttr = {
    theme: "colored",
    position: "top-center",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    };
  const [tableData,settableData] = useState([]);   
  const [loading, setloading] = useState(true);
  const [view, setview] = useState('list');
  const [searchTerm, setsearchTerm] = useState(null);
  const [page, setpage] = useState({page : 1, count : 1,total:0, next: null,previous: null});
  const [showDelete, setshowDelete] = useState(false);
  const [selRow, setSelRow] = useState(null)


  const setLoader = (val) => {
    setloading(val);
  }
  let mapVal = {
    veg : 'Veg',
    non_veg : 'Non-veg',
    both : 'Veg & Non-veg'
  }

  let breadcrumbData = [
    {
      title : 'Mandap',
      href : '#'
    }
  ]

  const manipulateData = (res,pageNum) => {
    let count =  Math.ceil(res.data.count/10)
    setpage({page:pageNum || page.page, count:count,total:res.data.count, next:res.data.next,previous:res.data.previous});
    settableData(res.data.results);
    setLoader(false);
  }


  const handlePagination = (e, newPage) => {
    setLoader(true);
    let url = config.service+'/masters/vendor_mandap/?&page='+newPage;
    if(!empty(searchTerm)) url += '&search='+searchTerm;
    get(url,tokenKey).then(res=>{
      manipulateData(res,newPage);
      setpage({...page, page:newPage});
      window.scrollTo({
        top: 0,
        behavior: 'instant',
      })
    }).catch(err => {
      setLoader(false);
    })
  }

  const handleTablePagination = (type,{page}) => {
    if(type === 'pagination'){
      setLoader(true);
      let url = config.service+'/masters/vendor_mandap/?&page='+page;
      if(!empty(searchTerm)) url += '&search='+searchTerm;
      get(url,tokenKey).then(res=>{
        manipulateData(res,page);
        window.scrollTo({
          top: 0,
          behavior: 'instant',
        })
      }).catch(err => {
        setLoader(false);
      })
    }
  }

  const handleClose = () =>{ setshowDelete(false);setSelRow(null)};

  const deleteRow = () => {
    setshowDelete(false)
    setLoader(true);
    axios.delete(config.service+'/masters/vendor_mandap/'+selRow.id,tokenKey).then(resp=>{
      get(config.service+'/masters/vendor_mandap/',tokenKey).then(res=>{
        manipulateData(res,1)
    })
    }).catch(err=>{
      if(err.response.status === 401) window.location.href = '/'; setLoader(false);
      toast.error('Deletion Failed', toastAttr);
    })
  }

  const renderForm = (row) => {
      if(row) history.push({
          pathname:'/vendorForm',
          state : {
            id: row.id,
            vendor_id : vendorId,
          editMode:true,
          tabValue:'mandapam'
          }
      })
    else history.push({
        pathname:'/vendorForm',
        state : {
        vendor_id : vendorId,
        tabValue:'mandapam'
        }
    })
  }

  const renderOnSearch = searchTerm => {
    setLoader(true);
    get(config.service+'/masters/vendor_mandap/?&search='+searchTerm,tokenKey).then(res=>{
      setsearchTerm(searchTerm)
      manipulateData(res,1)
    }).catch(err=>{
      if(err.response.status === 401) window.location.href = '/';setLoader(false);
      toast.error('Search Failed', toastAttr);
    })
  }

  useEffect(() => {
    setLoader(true);
    get(config.service+'/masters/vendor_mandap/',tokenKey).then(res=>{
      manipulateData(res,1)
    }).catch(err=>{ if(err.response.status === 401) window.location.href = '/';setLoader(false);})
  }, []);
  const cols = [
    {
      dataField : 'mandap_name',
      text:'Mandap Name',
      headerStyle : () => { return{width : '400px'}},
      formatter:(cell,row)=>{
        let ac = row.ac_type && row.ac_type === 'ac' ? '  ( A/C )' : ''
        return(
          <div>{cell + ac}</div>
        )}
    },
    {
      dataField : 'mandap_type_name',
      text:'Mandap Type',
      headerStyle : () => { return{width : '180px'}}
    },
    {
      dataField : 'area_sqft',
      text:'Area (sqft)',
      headerStyle : () => { return{width : '130px'}}
    },
    {
      dataField : 'hall_sq_ft',
      text:'Hall (sqft)',
      headerStyle : () => { return{width : '130px'}}
    },
    {
      dataField : 'seating_capacity',
      text:'Seating Capacity',
      headerStyle : () => { return{width : '130px'}}
    },{
      dataField : 'food_type',
      text:'Food Type',
      headerStyle : () => { return{width : '150px'}},
      formatter:(cell)=>{
        return(<div>{mapVal[cell]}</div>)
      }
    },
    {
      dataField : 'id',
      text:'Actions',
      headerStyle : () => { return{width : '80px'}},
      events : {
        OnClick : e=>{return e.stopPropagation(); }
      },
      formatter:(cell,row)=>{
        return(
        <div className='tableActions'>
          <React.Fragment>
            <Link to={{pathname:"/vendorForm",state:{id:row.id, editMode:true,tabValue:'mandapam'}}}>
              <FaEdit title='Edit' className='color-green' />
            </Link>
            {/* <HiOutlineTrash className='themeColor actionIcon' onClick={()=>{setshowDelete(true);setSelRow(row);}} /> */}
          </React.Fragment>
        </div>)
      }
    }
  ];

  const rowEvents = {
    onClick : (e,row) =>{
      history.push({
        pathname:"/vendorForm",
        state : {
          id: row.id,
        editMode:true,
        tabValue : 'mandapam'
        }
    })
    }
  }

  let options = paginationFactory({
    page:page.page,
    sizePerPage: 20,
    paginationSize:3,
    hideSizePerPage:true,
    hidePageListOnlyOnePage : true,
    totalSize:page.total
  })

  return (
  <Container className="vendor">
    <Row>
  <Col md={12} className='contBody'>
    <Card>
      <CardBody>
        <div className="card__title pageNav">
          <div className="bold-text">
          <Breadcrumbs data={breadcrumbData} />
          </div>
        </div>
        <Row className='formBtnsDiv mar-b-10'>
  <Col md={8}>
    <Button variant='primary' onClick={e=>renderForm()}>Create</Button>
  </Col>
  <Col md={4}>
    <Search callbackFn={e=>renderOnSearch(e)} />
  </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div className='viewIcons'>
              <span><FaListUl title='Card View' onClick={()=>{setview('list')}} /></span>
              <span><FaTable title='List View' onClick={()=>{setview('table')}} /></span>
            </div>
          </Col>
        </Row>
        <Row className='vendorList'>
        <Loader loading={loading}>
            {view === 'list' ? 
            tableData.length>0 ? tableData.map(row=>{
              let ac = row.ac_type && row.ac_type === 'ac' ? 'A/C' : '';
              let mandapImg = icon;
                {row.vendor_image_ids && row.vendor_image_ids.length > 0 && row.vendor_image_ids.map(obj=>{
                  if(obj.cover_image) mandapImg = obj.image;
                })}

               return (
                <Col md={5} className='divCont' style={{display:'block'}}>
                    <Row>
                      <Col md={5}>
                      <div className='child' onClick={e=>renderForm(row)} style={{margin:'15px 0'}}>
                      <img className="mandapImage" src={mandapImg} alt="mandap" />
                        </div>
                      </Col>
                      <Col md={5}>
                      <div className='child' onClick={e=>renderForm(row)}>
                      <div className='mandapHeader'> <span>{row.mandap_name} </span><span>{ac}</span></div>
                          {/* <div>Type : {row.mandap_type}</div> */}
                          {/* <div>City : {row.city_name}</div> */}
                          <div>Mandap Type : {row.mandap_type_name}</div>
                            <div>Area (sqft) : {row.area_sqft}</div>
                            <div>Hall (sqft) : {row.hall_sq_ft}</div>
                            <div>Seating Capacity : {row.seating_capacity}</div>   
                            <div>Food Type : {mapVal[row.food_type]}</div> 
     
                      </div>
                      </Col>
                      <Col md={2}>
                      <div className='child' style={{margin : '15px 0'}}>
                          <HiPencilAlt className='color-green actionIcon' onClick={e=> renderForm(row)}/>
                          {/* <HiOutlineTrash className='themeColor actionIcon' onClick={()=>{setshowDelete(true);setSelRow(row);}} /> */}
                      </div>
                      </Col>
                    </Row>
                  </Col>
                )
            }) : <div className='textCenter'>No data found</div>
            : <BootstrapTable remote bootstrap4  wrapperClasses='table-responsive  pad-20' data={tableData} columns={cols} 
            keyField='id' containerStyle={{width:'100%'}} hover noDataIndication='No data found' rowEvents={rowEvents}
            pagination={options} onTableChange={handleTablePagination}  />
}
{view === 'list' && !empty(tableData) ? <Row className='common-pagination'>
        <Col md={6} />
        <Col md={6}>
        <Pagination className='paginate' count={page.count} page={page.page} onChange={handlePagination} color='primary' />
        </Col>
      </Row> : ''}
            </Loader>
            <Col md={6}></Col>
        </Row>
      </CardBody>
    </Card>
  </Col>
  </Row>
  <Dialog open={showDelete} onClose={handleClose} maxWidth='md'>
        <DialogTitle>Confirm Deletion <FaTimes onClick={()=>setshowDelete(false)} className='popup-close' title='close' /></DialogTitle>
        <DialogContent>
          <div style={{paddingRight:'30px'}}>Are you sure to delete ?</div>
        </DialogContent>
        <DialogActions>
          <Button onClick={deleteRow}>Delete</Button>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
  <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        />
  </Container>
);
};

export default MandapList;
