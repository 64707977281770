import React from "react";
import { Route, Switch } from "react-router-dom";
import Layout from "../Layout/index";
import MainWrapper from "./MainWrapper";
import LogIn from "../LogIn/index";
import Signup from "../LogIn/components/Signup";
import OTPSignIn from "../LogIn/components/OTPSignIn";
import ResetPassword from "../LogIn/components/ResetPassword";
import Dashboard from "../Components/Dashboard";
import Vendor from "../Components/Vendor";
import VendorForm from "../Components/Vendor/form";
import MandapList from "../Components/Vendor/views/mandapList";
import Booking from "../Components/Vendor/views/bookingList";
import BookingForm from "../Components/Vendor/views/booking";
import Cashback from "../Components/Cashback";
import ViewPayment from "../Components/Payment/ViewPayment";
import ViewPaymentForm from "../Components/Payment/ViewPayment/form.js";
import Pricing from "../Components/Pricing";
import PricingForm from "../Components/Pricing/form";
import UpdateDates from "../Components/UpdateDates";

const Pages = () => (
  <Switch>
    <Route path="/dashboard" component={Dashboard} />
    <Route path="/vendor" component={Vendor} />
    <Route path="/vendorForm" component={VendorForm} />
    <Route path="/mandapList" component={MandapList} />
    <Route path="/booking" component={Booking} />
    <Route path="/bookingForm/:bookingId" component={BookingForm} />
    <Route path="/bookingForm" component={BookingForm} />
    <Route path="/QRCodeBooking/:vendorId" component={BookingForm} />
    <Route path="/profile" component={VendorForm} />
    <Route path="/cashback" component={Cashback} />
    <Route path="/ViewPayment" component={ViewPayment} />
    <Route path="/ViewPaymentForm" component={ViewPaymentForm} />
    <Route path="/pricing" component={Pricing} />
    <Route path="/pricingForm" component={PricingForm} />
    <Route path="/updateDates" component={UpdateDates} />
  </Switch>
);

const wrappedRoutes = () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <Route path="/" component={Pages} />
    </div>
  </div>
);

const Router = () => (
  <MainWrapper>
    <main>
      <Switch>
        <Route exact path="/" component={LogIn} />
        {/* <Route exact path="/log_in" component={LogIn} /> */}
        <Route exact path="/signup" component={Signup} />
        <Route exact path="/OTPSignIn" component={OTPSignIn} />
        <Route exact path="/resetPassword/:token" component={ResetPassword} />
        <Route path="/" component={wrappedRoutes} />
      </Switch>
    </main>
  </MainWrapper>
);

export default Router;
